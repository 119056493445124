import './list.scss';
import dataApi from '@/api/manage/energy.js';
/*
 * 能量值充值模板
 *  */
export default {
  name: 'energy-list',
  components: {},
  data() {
    return {
      page: {
        pageSize: 20,
        pageSizes: [20, 30, 40, 50, 100]
      },
      tableData: [],
      tbleOption: {
        total: 20,
        align: 'center',
        cellBtn: false,
        cancelBtn: false,
        ...this.$store.getters.tableConfig,
        column: [
          {
            label: '名称',
            prop: 'title',
            type: 'input',
            search: true,
            placeholder: '名称 ',
            addDisplay: 'true',
            editDisplay: 'true',
            required: true,
            rules: [
              {
                required: true,
                message: '名称必须填写'
              }
            ],
            span: 24
          },
          {
            label: '价格(元)',
            prop: 'price',
            type: 'input',
            addDisplay: true,
            editDisplay: true,
            span: 12,
            required: true,
            rules: [
              {
                required: true,
                message: '价格必须填写'
              }
            ]
          },
          {
            label: '能量值',
            prop: 'energy',
            type: 'input',
            addDisplay: true,
            editDisplay: true,
            span: 12,
            required: true,
            rules: [
              {
                required: true,
                message: '能量值必须填写'
              }
            ]
          },
          {
            label: '使用场景',
            prop: 'is_scope',
            type: 'select',
            addDisplay: true,
            editDisplay: true,
            dataType: 'string',
            search: 'true',
            dicUrl: `${this.$baseUrl}general/get_dict`,
            dicMethod: 'post',
            dicFlag: true,
            filterable: false,
            multiple: false,
            dicQuery: {
              dict_type: 'sys_scenario'
            },
            required: true,
            rules: [
              {
                required: true,
                message: '请选择使用场景'
              }
            ]
          },
          {
            // 排序
            type: 'number',
            value: 0,
            label: '排序',
            span: 12,
            display: true,
            prop: 'sorting'
          },
          {
            label: '说明 ',
            prop: 'remark',
            type: 'textarea',
            addDisplay: true,
            editDisplay: true,
            span: 24
          },
          {
            label: '状态',
            prop: 'status',
            type: 'radio',
            dataType: 'string',
            addDisplay: true,
            editDisplay: true,
            search: 'true',
            value: 1,
            dicData: [
              {
                label: '正常',
                value: 1
              },
              {
                label: '停用',
                value: 0
              }
            ]
          },

          {
            label: '创建者',
            addDisplay: false,
            editDisplay: false,
            prop: 'update_user'
          },
          {
            label: '更新时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'update_time'
          }
        ]
      },

      searchData: {}
    };
  },
  computed: {},
  filters: {},
  methods: {
    // 分页
    onLoad(page) {
      this.page = page;
      this.getList();
    },
    // 筛选
    searchChange(params, done) {
      console.log(params);
      this.searchData = params;
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset() {
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },
    // 添加
    handleSubmit(form, done, loading) {
      console.log('form', form, loading);
      dataApi.add.r(form).then((res) => {
        console.log(res);
        done(form);
        loading();
        this.getList();
      });
      done();
    },
    // 删除
    rowDel(form) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('删除数据', form);
        dataApi.del.r({ id: form.id }).then((res) => {
          console.log(res);
          this.getList();
          this.$message.success('删除成功');
        });
      });
    },
    // 修改
    rowUpdate(form, index, done, loading) {
      console.log('index', form, index);
      dataApi.update.r(form).then((res) => {
        console.log(res);
        this.getList();
        done(form);
        loading();
      });
      done();
    },
    // 获取
    getList() {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      dataApi.getList.r(postData).then((res) => {
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    }
  }
};
