import http from '@/utils/http';

/**
 * 轮播图
 */
export default {
  getList: {
    p: 'post,/manage/energytopuptpl/getlist',
    r: (data) =>
      http({ url: '/manage/energytopuptpl/getlist', method: 'post', data })
  },
  add: {
    p: 'post,/manage/energytopuptpl/add',
    r: (data) =>
      http({ url: '/manage/energytopuptpl/add', method: 'post', data })
  },
  update: {
    p: 'post,/manage/energytopuptpl/update',
    r: (data) =>
      http({ url: '/manage/energytopuptpl/update', method: 'post', data })
  },
  del: {
    p: 'post,/manage/energytopuptpl/delete',
    r: (data) =>
      http({ url: '/manage/energytopuptpl/delete', method: 'post', data })
  },
  getOne: {
    p: 'post,/manage/energytopuptpl/getOne',
    r: (data) =>
      http({ url: '/manage/energytopuptpl/getOne', method: 'post', data })
  }
};
